import { useEffect, useState } from 'react'
import { Image, ImageResponse } from '../coretypes'
import { ImageThumbView } from '../views/ImageThumbView'
import { useNavigate, useParams } from 'react-router-dom';


export const ImageSetView = () => {

  const navigate = useNavigate();
  const params = useParams()
  const cid = params["cid"]
  const [data, setData] = useState<Image[]>([]);

  const refresh = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.YmxhaA.FgIs5phYASBwrk4vtwnIYXlhEw78CPwr9bmL9JinQdY'
    };

    fetch(`https://pixels-service.herokuapp.com/image/collection/${cid}`, {
      method: "GET",
      credentials: 'include',
      headers: headers,
    })
      .then((response) => response.json())
      .then((networkData: ImageResponse) => {
        setData(networkData.list)
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <div className="App">
      <p className='intro'>Images: {data.length}</p>
      <div className="imageset">
        {data.map(i => <div key={i.imageId}>{ImageThumbView(i, navigate)}</div>)}
      </div>
    </div>
  )
}


