import { getBannerUrl } from "../common"
import { Collection } from "../coretypes"

const imageWidth: number = 563
const imageHeight: number = 128
const placeHolderHeight: number = imageHeight / 4.0

const BannerElement = (c: Collection): React.ReactNode => {

    if (c.bannerImagePath != null && c.bannerImageName) {
        const url: string = getBannerUrl(c)
        const alt = `${c.bannerImagePath}/${c.bannerImageName}`
        return (
            <img alt={alt} width={imageWidth} height={imageHeight} src={url} />
        )
    }

    const name: string = !c.displayName || c.displayName.length === 0 ? c.collectionName : c.displayName

    return (
        <div className="bannerdiv">
            <svg width={imageWidth} height={placeHolderHeight} xmlns="http://www.w3.org/2000/svg">
                <rect width={imageWidth} height={placeHolderHeight} x="0" y="0" rx="6" ry="6" fill="#EADDCA" />
            </svg>
            <div className="bannertext">{name}</div>
        </div>
    )
}

export const BannerView = (c: Collection): React.ReactNode => {

    if (c.isGroup) {
        return (
            <a href={`/collection?cid=${c.collectionId}`}>
                {BannerElement(c)}
            </a>
        )
    } else {
        return (
            <a href={`/collection/${c.collectionId}/images`}>
                {BannerElement(c)}
            </a>
        )
    }
}


