import './index.css'
import { Navbar } from './Navbar'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom'
import { TagsView } from './pages/TagsView'
import { CollectionView } from './pages/CollectionView'
import { ImageSetView } from './pages/ImageSetView'
import { ImageView } from './pages/ImageView'

export const App = () => {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<CollectionView />} />
                <Route path="/collection/:cid?" element={<CollectionView />} />
                <Route path="/tags" element={<TagsView />} />
                <Route path="/collection/:cid/images" element={<ImageSetView />} />
                <Route path="/image/:iid" element={<ImageView />} />
            </Routes>
        </Router>
    )
}
