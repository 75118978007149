import { useEffect, useState } from 'react'
import { Collection, CollectionResponse } from '../coretypes'
import { BannerView } from '../views/BannerView'
import { useSearchParams } from 'react-router-dom';


export const CollectionView = () => {

  const [data, setData] = useState<Collection[]>([]);
  const [searchParams] = useSearchParams();

  const orderCollections = (input: Collection[]): Collection[] => {
    const wBanners = input.filter(f => !!f.bannerImageId)
    const woBanners = input.filter(f => !f.bannerImageId)
    return wBanners.concat(woBanners)
  }

  const refresh = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.YmxhaA.FgIs5phYASBwrk4vtwnIYXlhEw78CPwr9bmL9JinQdY'
    };

    const params = searchParams.getAll("cid")
    const cid: string = params.length === 0 ? "root" : `${params[0]}`

    fetch(`https://pixels-service.herokuapp.com/collection/${cid}`, {
      method: "GET",
      credentials: 'include',
      headers: headers,
    })
      .then((response) => response.json())
      .then((networkData: CollectionResponse) => {
        setData(orderCollections(networkData.list))
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <div className="App">
      <p className='intro'>Collections: {data.length}</p>
      <div className="collection">
        {data.map(c => <div key={c.collectionId}>{BannerView(c)}</div>)}
      </div>
    </div>
  )
}


