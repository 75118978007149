import { getImageTitle, getImageUrl } from "../common"
import { Image } from "../coretypes"

const imageWidth: number = 256
const imageHeight: number = 170

export const ImageThumbView = (image: Image, navigate: any): React.ReactNode => {

    const url = getImageUrl(image)
    const title = getImageTitle(image)

    const navigateToImage = () => {
        navigate(`/image/${image.imageId}`, { state: image });
    }

    return (
        <a onClick={() => { navigateToImage() }}>
            <div className="image">
                <img alt="" title={title} width={imageWidth} height={imageHeight} src={url} />
            </div>
        </a>
    )
}

