import { Collection, Image } from "./coretypes"

const imagesUrl: string = 'http://gallery.rezio.net/images'

export const getBannerUrl = (collection: Collection): string => {
    return `${imagesUrl}/${collection.bannerImagePath}/${collection.bannerImageName}`
}

export const getImageUrl = (image: Image): string => {
    return `${imagesUrl}/${image.imagePath}/${image.imageName}`
}

export const getImageTitle = (image: Image): string => {
    return `${image.imagePath}/${image.imageName}`
}