import { useEffect, useState } from 'react'
import { Tag, TagResponse } from '../coretypes'
import { TagLink } from '../views/TagLink';

export const TagsView = () => {

  const [data, setData] = useState<Tag[]>([]);

  const refresh = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.YmxhaA.FgIs5phYASBwrk4vtwnIYXlhEw78CPwr9bmL9JinQdY'
    };

    fetch("https://pixels-service.herokuapp.com/tag", {
      method: "GET",
      credentials: 'include',
      headers: headers,
    })
      .then((response) => response.json())
      .then((networkData: TagResponse) => {
        setData(networkData.list)
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <div className="App">
      <p className='intro'>Tags: {data.length}</p>
      <div className="taglist">
        {data.map(tag => TagLink(tag))}
      </div>
    </div>
  );

}

