import { useLocation } from 'react-router-dom';
import { getImageUrl, getImageTitle } from '../common'
import { Image, ImageData } from '../coretypes'
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export const ImageView = () => {
  const params = useParams()
  const iid = params["iid"]

  const [image, setImage] = useState<Image | null>(null);
  const location = useLocation();
  const imageDesc: Image | null = location.state

  const fetchImageData = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.YmxhaA.FgIs5phYASBwrk4vtwnIYXlhEw78CPwr9bmL9JinQdY'
    };

    fetch(`https://pixels-service.herokuapp.com/image/${iid}/meta`, {
      method: "GET",
      credentials: 'include',
      headers: headers,
    })
      .then((response) => response.json())
      .then((networkData: ImageData) => {
        // commit metadata
        if (!imageDesc && !image) {
          setImage(networkData.image)
        }
      })
      .catch((error) => console.log(error));
  }

  if (!!imageDesc && !image) {
    setImage(imageDesc)
  } else {
    fetchImageData()
  }

  if (!!image) {
    const title = getImageTitle(image!)
    const url = getImageUrl(image!)

    return (
      <div className="App">
        <div className="heading">
          <p className='intro'>{title} <a href={url} target='_blank'>[link]</a></p>
          <p>Metadata</p>
        </div>
        <div className="image">
          <img alt="" title={title} width='100%' src={url} />
        </div>
      </div>
    )
  } else {
    return (
      <div className="App">
        <p className='intro'>loading...</p>
        <div className="image-placeholder">
        </div>
      </div>
    )
  }
}


