import { Tag } from "../coretypes"

export const TagLink = (tag: Tag) => {
    return (
        <div className="tag" key={tag.tagId}>
            {tag.tagName}
            <span className="usage">
                ({tag.usageCount})
            </span>
        </div>
    )
}
